import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"background fill-height d-block",attrs:{"fluid":""}},[_c('u-project-top-nav',{attrs:{"title":_vm.activeProjectName,"extended":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('u-stepper-progression',{attrs:{"step":_vm.stepper.step,"sub-step":_vm.stepper.subSteps,"subtitle":_vm.stepper.subtitle,"title":_vm.stepper.title},on:{"click:prev":function($event){return _vm.$router.push(_vm.stepper.prevLink)},"click:next":function($event){return _vm.$router.push(_vm.stepper.nextLink)}}})]},proxy:true}])}),_c(VRow,{staticClass:"ma-0 justify-space-between align-center"},[_c(VContainer,{staticClass:"pb-0 col",attrs:{"fluid":""}},[_c('u-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('h1',{staticClass:"secondary--text"},[_vm._v("Camera Configuration")])],1),_c(VContainer,{staticClass:"pb-0 col d-flex justify-end",attrs:{"fluid":""}},_vm._l((_vm.selectedDetectionStatus),function(ref,index){
var status = ref.status;
var timestamp = ref.timestamp;
return _c('u-status-indicator',{key:index,attrs:{"camera-index":_vm.selectedCameraIndex,"status":status.toLowerCase(),"timestamp":timestamp ? new Date(timestamp).toDateString() : '--'}})}),1)],1),_c(VRow,{staticClass:"content ma-2"},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSheet,{staticClass:"card-border fill-height overflow-hidden",attrs:{"outlined":"","rounded":"lg"}},[_c('u-canvas',{ref:"canvas",attrs:{"action-type":_vm.canvasActionType,"image-url":_vm.activeCamera.camera_image},on:{"updateArea":_vm.updateCanvasArea,"updateMarkers":_vm.updateCanvasMarkers}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSheet,{staticClass:"card-border fill-height overflow-hidden",attrs:{"outlined":"","rounded":"lg"}},[_c('u-map',{ref:"map",staticClass:"fill-height",attrs:{"center":_vm.cameraMapCoordinates,"allow-map-search":"","show-marker-on-click":"","show-navigation-controls":"","zoom":"18"},on:{"markersUpdated":_vm.updateMapMarkers}})],1)],1)],1),_c(VRow,{staticClass:"px-4 ma-1",attrs:{"align":"center","justify":"space-between"}},[_c(VContainer,{staticClass:"col ma-0 pa-0 d-flex align-center",attrs:{"fluid":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('u-dropdown-alt',{attrs:{"items":_vm.projectCameras,"item-text":"camera_name","large":"","offset-y":"","placeholder":"Select a camera","prepend-inner-icon":"fas fa-video","return-object":"","top":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('camera-icon',{attrs:{"number":_vm.getCameraIndex(item) + 1,"size":24}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.camera_name))])]}},(_vm.selectedCameraIndex)?{key:"prepend-inner",fn:function(){return [_c('camera-icon',{staticClass:"mr-1",attrs:{"number":_vm.selectedCameraIndex,"size":24}})]},proxy:true}:null],null,true),model:{value:(_vm.activeCamera),callback:function ($$v) {_vm.activeCamera=$$v},expression:"activeCamera"}})],1),_c('u-preview-toggle-group',{attrs:{"items":_vm.canvasDrawTypes,"item-value":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var item = ref.item;
return [_c(VIcon,{attrs:{"color":active ? 'white' : 'secondary',"large":""}},[_vm._v(" "+_vm._s(item.icon)+" ")])]}}]),model:{value:(_vm.canvasActionType),callback:function ($$v) {_vm.canvasActionType=$$v},expression:"canvasActionType"}})],1),_c(VContainer,{staticClass:"col col-auto ma-0 pa-0 d-flex align-center",attrs:{"fluid":""}},[_c('u-button',{staticClass:"text-capitalize font-weight-bold col-auto mr-2",class:_vm.geotagLoader ? 'white--text' : 'secondary--text',attrs:{"color":_vm.geotagLoader ? 'gray-1' : 'primary',"disbled":_vm.geotagLoader,"loadState":_vm.geotagLoader,"large":""},on:{"click":_vm.geotagVerification}},[_vm._v(" Geotag ")]),(_vm.cameraIsConfigured)?_c('u-button',{staticClass:"text-capitalize font-weight-bold secondary--text col-auto",attrs:{"color":"primary","large":""},on:{"click":_vm.launchProcessSummaryModal}},[_vm._v(" Process ")]):_vm._e()],1)],1),(_vm.showCheckGeotaggingModal)?_c('geotagging-modal',{attrs:{"map-annotations":_vm.mapCoordinates,"map-center":_vm.cameraMapCoordinates,"roi-projections":_vm.roiProjections},on:{"click:close":function($event){_vm.showCheckGeotaggingModal = false},"click:proceed":_vm.proceedToProcessSummary}}):_vm._e(),_c('process-summary-modal',{attrs:{"cameras":[{ activeCamera: _vm.activeCamera, videoList: _vm.videoList }],"processLoader":_vm.processVideosLoader},on:{"click:start-processing":_vm.processVideos},model:{value:(_vm.showProcessSummaryModal),callback:function ($$v) {_vm.showProcessSummaryModal=$$v},expression:"showProcessSummaryModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }