<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="activeProjectName" extended>
      <template #extension>
        <u-stepper-progression
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <v-row class="ma-0 justify-space-between align-center">
      <!--  Breadcrumbs  -->
      <v-container class="pb-0 col" fluid>
        <u-breadcrumbs :items="breadcrumbItems" />
        <h1 class="secondary--text">Camera Configuration</h1>
      </v-container>

      <v-container class="pb-0 col d-flex justify-end" fluid>
        <u-status-indicator
          v-for="({ status, timestamp }, index) in selectedDetectionStatus"
          :key="index"
          :camera-index="selectedCameraIndex"
          :status="status.toLowerCase()"
          :timestamp="timestamp ? new Date(timestamp).toDateString() : '--'"
        />
      </v-container>
    </v-row>

    <v-row class="content ma-2">
      <!--  Canvas  -->
      <v-col cols="12" md="6">
        <v-sheet
          class="card-border fill-height overflow-hidden"
          outlined
          rounded="lg"
        >
          <u-canvas
            ref="canvas"
            :action-type="canvasActionType"
            :image-url="activeCamera.camera_image"
            @updateArea="updateCanvasArea"
            @updateMarkers="updateCanvasMarkers"
          />
        </v-sheet>
      </v-col>

      <!--  Map  -->
      <v-col cols="12" md="6">
        <v-sheet
          class="card-border fill-height overflow-hidden"
          outlined
          rounded="lg"
        >
          <u-map
            ref="map"
            :center="cameraMapCoordinates"
            allow-map-search
            class="fill-height"
            show-marker-on-click
            show-navigation-controls
            zoom="18"
            @markersUpdated="updateMapMarkers"
          ></u-map>
        </v-sheet>
      </v-col>
    </v-row>

    <!--  Action Buttons  -->
    <v-row align="center" class="px-4 ma-1" justify="space-between">
      <!-- Toolbar -->
      <v-container class="col ma-0 pa-0 d-flex align-center" fluid>
        <!-- Camera Selector -->
        <v-col cols="auto">
          <u-dropdown-alt
            v-model="activeCamera"
            :items="projectCameras"
            item-text="camera_name"
            large
            offset-y
            placeholder="Select a camera"
            prepend-inner-icon="fas fa-video"
            return-object
            top
          >
            <!--  List Item  -->
            <template #item="{ item }">
              <camera-icon :number="getCameraIndex(item) + 1" :size="24" />
              <span class="ml-2">{{ item.camera_name }}</span>
            </template>

            <!--  Input inner slot (Camera Icon)  -->
            <template v-if="selectedCameraIndex" #prepend-inner>
              <camera-icon
                :number="selectedCameraIndex"
                :size="24"
                class="mr-1"
              />
            </template>
          </u-dropdown-alt>
        </v-col>

        <!--  Canvas Actions  -->
        <u-preview-toggle-group
          v-model="canvasActionType"
          :items="canvasDrawTypes"
          item-value="name"
        >
          <template v-slot="{ active, item }">
            <v-icon :color="active ? 'white' : 'secondary'" large>
              {{ item.icon }}
            </v-icon>
          </template>
        </u-preview-toggle-group>
      </v-container>

      <!-- Next Navigation -->
      <v-container class="col col-auto ma-0 pa-0 d-flex align-center" fluid>
        <u-button
          :class="geotagLoader ? 'white--text' : 'secondary--text'"
          :color="geotagLoader ? 'gray-1' : 'primary'"
          :disbled="geotagLoader"
          :loadState="geotagLoader"
          class="text-capitalize font-weight-bold col-auto mr-2"
          large
          @click="geotagVerification"
        >
          Geotag
        </u-button>
        <u-button
          v-if="cameraIsConfigured"
          class="text-capitalize font-weight-bold secondary--text col-auto"
          color="primary"
          large
          @click="launchProcessSummaryModal"
        >
          Process
        </u-button>
      </v-container>
    </v-row>

    <!--  Check Geotagging Modal  -->
    <geotagging-modal
      v-if="showCheckGeotaggingModal"
      :map-annotations="mapCoordinates"
      :map-center="cameraMapCoordinates"
      :roi-projections="roiProjections"
      @click:close="showCheckGeotaggingModal = false"
      @click:proceed="proceedToProcessSummary"
    />

    <process-summary-modal
      v-model="showProcessSummaryModal"
      :cameras="[{ activeCamera, videoList }]"
      :processLoader="processVideosLoader"
      @click:start-processing="processVideos"
    />
  </v-container>
</template>

<script>
import {
  UBreadcrumbs,
  UButton,
  UCanvas,
  UDropdownAlt,
  UMap,
} from "@/components/base";
import {
  UPreviewToggleGroup,
  UProjectTopNav,
  UStatusIndicator,
  UStepperProgression,
} from "@/components/common";
import { GeotaggingModal, ProcessSummaryModal } from "@/components/modals";
import { CameraIcon } from "@/components/icons";

import { CONFIG_STEPPER } from "@/utils/navStepper.data";
import { CAMERA_CONFIGURATION_BREADCRUMBS } from "@/utils/breadcrumbs.data";
import { beforeWindowUnload } from "@/utils";
import { CANVAS_ACTIONS } from "@/utils/const";
import ApiService from "@/services/ApiService";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "CameraConfiguration",
  components: {
    CameraIcon,
    UStatusIndicator,
    UDropdownAlt,
    GeotaggingModal,
    UCanvas,
    UStepperProgression,
    UMap,
    UButton,
    UBreadcrumbs,
    UProjectTopNav,
    UPreviewToggleGroup,
    ProcessSummaryModal,
  },
  data: () => ({
    stepper: CONFIG_STEPPER.cameraConfig,
    breadcrumbItems: CAMERA_CONFIGURATION_BREADCRUMBS,
    projectCoordinates: [77.59796, 12.96991],
    canvasActionType: undefined,
    canvasDrawTypes: [
      { icon: "$area", name: CANVAS_ACTIONS.ROI },
      { icon: "$movement", name: CANVAS_ACTIONS.PIN },
    ],
    cameraButtonItems: [],

    // Geotagging
    showCheckGeotaggingModal: false,
    mapCoordinates: [],
    roiProjections: [],
    videoList: [],
    videoIds: [],
    // Process Summary
    showProcessSummaryModal: false,

    // Button loader
    geotagLoader: false,
    processVideosLoader: false,
  }),
  computed: {
    ...mapGetters("CameraConfig", [
      "getConfigByCamera",
      "selectedDetectionStatus",
    ]),
    ...mapGetters("Project", ["activeProjectName", "activeProjectId"]),
    ...mapState("Project", ["projectCameras"]),
    activeCamera: {
      get() {
        return this.$store.state.CameraConfig.activeCamera ?? {};
      },
      set(value) {
        this.$store.commit("CameraConfig/SET_ACTIVE_CAMERA", value);
      },
    },

    // Camera Index for Camera Icon
    selectedCameraIndex() {
      return (
        this.projectCameras.findIndex(
          (camera) => camera.camera_id === this.activeCamera.camera_id,
        ) + 1 || ""
      );
    },

    // Camera lat long values for Map
    cameraMapCoordinates() {
      const { lat = 77, lon = 12 } = this.activeCamera?.coordinates ?? {};
      return [lon, lat];
    },

    cameraIsConfigured() {
      return (
        this.selectedDetectionStatus[0]?.status?.toLowerCase() !== "pending"
      );
    },
  },
  watch: {
    activeCamera(newValue) {
      // Get markers for camera from store
      const {
        mapMarkers = [],
        canvasMarkers = [],
        canvasArea = [],
      } = this.getConfigByCamera(newValue.camera_id);

      // Update Map with new camera markers
      this.$refs.map.updateMapAnnotations(mapMarkers);

      // Update Canvas with new camera markers
      this.$refs.canvas.updateAnnotations(
       [],
        canvasMarkers,
        canvasArea,
        this.activeCamera.camera_image,
      );
    },
  },
  created() {
    window.addEventListener("beforeunload", beforeWindowUnload);
  },
  mounted() {
    !this.projectCameras.length && this.getProjectCameras(this.activeProjectId);
    this.getDetectionStatuses();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", beforeWindowUnload);
  },
  methods: {
    ...mapActions("CameraConfig", [
      "updateCurrentConfig",
      "getDetectionStatuses",
    ]),
    ...mapActions("Project", ["getProjectCameras"]),

    // Camera Index for Camera Icon
    getCameraIndex(camera) {
      return this.projectCameras.findIndex(
        (cam) => cam.camera_id === camera.camera_id,
      );
    },

    // Update Map Markers for camera in store
    updateMapMarkers(markers) {
      if (this.activeCamera.camera_id)
        this.updateCurrentConfig({ type: "MAP_MARKERS", payload: markers });
    },

    // Update Canvas Markers for camera in store
    updateCanvasMarkers(markers) {
      if (this.activeCamera.camera_id)
        this.updateCurrentConfig({ type: "CANVAS_MARKERS", payload: markers });
    },

    // Update Canvas Area for camera in store
    updateCanvasArea(area) {
      if (this.activeCamera.camera_id)
        this.updateCurrentConfig({ type: "CANVAS_AREA", payload: area });
    },

    // Perform geotagging on canvas and map annotations
    async geotagVerification() {
      const { canvasArea, canvasMarkers, mapMarkers } =
        this.$store.state.CameraConfig.configs[this.activeCamera.camera_id];

      const mapCoordinates = mapMarkers.reduce(
        (acc, { coordinates: { lat, lng } }) => [...acc, [lng, lat]],
        [],
      );

      const params = {
        config_json: {
          camera_coordinates: canvasMarkers,
          map_coordinates: mapCoordinates,
          camera_roi: canvasArea,
        },
      };
      try {
        this.geotagLoader = true;
        const res = await ApiService.geotag(
          this.activeCamera.camera_id,
          params,
        );
        this.geotagLoader = false;

        this.mapCoordinates = [
          ...res.data.map_coordinates,
          res.data.map_coordinates[0],
        ];
        this.roiProjections = res.data.roi_projection;

        this.showCheckGeotaggingModal = true;

        // await this.$router.push({ name: "Metric Configuration" });
      } catch (e) {
        this.geotagLoader = false;
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Error in geotagging",
          color: "error",
        });
      }
    },

    // List all camera videos
    async videosList() {
      try {
        const res = await ApiService.getCameraVideos(
          this.activeCamera.camera_id,
        );
        this.videoList = res.data;
        this.videoIds = this.videoList.map(({ id }) => id);
        this.showProcessSummaryModal = true;
      } catch (e) {
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Failed to fetch videos!",
          color: "error",
        });
      }
    },

    // Process Camera Videos
    async processVideos() {
      const params = {
        camera_id: this.activeCamera.camera_id,
        video_ids: this.videoIds,
        template_id: this.$store.state.Project.activeProject.template_id,
      };
      try {
        this.processVideosLoader = true;
        await ApiService.processVideos(params);
        this.processVideosLoader = false;
        this.showProcessSummaryModal = false;
        await this.$store.dispatch("Notification/addNotification", {
          text: "Successfully started processing videos!",
          color: "success",
        });
      } catch (e) {
        this.processVideosLoader = false;
        console.log(e);
        await this.$store.dispatch("Notification/addNotification", {
          text: "Failed to process videos!",
          color: "error",
        });
      }
    },

    // Show process summary modal
    proceedToProcessSummary() {
      this.videosList();
      this.showCheckGeotaggingModal = false;
    },

    launchProcessSummaryModal() {
      this.videosList();
      this.showCheckGeotaggingModal = false;
      this.showProcessSummaryModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}

.outline {
  outline: 2px solid var(--v-gray-7-base);
}
</style>
